.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  height: 12vh;
  background-color: rgba(250, 250, 250, 0.2);
  text-shadow: 5px 3px rgba(0, 0, 0, 0.5);

  transition-timing-function: ease-in;
  transition: all 0.4s;
}

.nav {
  font-size: 50px;
  transition: transform 450ms;
}

.nav:hover{
  transform: scale(1.08);
  cursor: pointer;
  color: rgb(105, 105, 105);
}

@media only screen and (max-width: 1024px) {
  .navbar {
    background-color: rgba(0, 0, 0, 0.661);
  }
}

@media only screen and (max-width: 600px) {
  .navbar {
    justify-content: inherit;
    height: 55px;
    background-color: rgba(0, 0, 0, 0.661);
  }
  
  .nav {
    margin-right: 6px;
    margin-left: 6px;
    font-size: 18px;
  }

}

@media only screen and (max-width: 320px) {
  .navbar {
    justify-content: inherit;
    height: 55px;
    background-color: rgba(0, 0, 0, 0.661);
  }
  
  .nav {
    margin-right: 6px;
    margin-left: 6px;
    font-size: 13px;
  }
}