.app {
  font-family: 'Cinzel', serif;
  min-height: 100vh;
  min-width: 100vw;
  background-image: url("./assets/background-horizontal-dark-wood.jpg");
}

.main-container {
  min-height: 80vh;
}

a {
  text-decoration: none;
  color: white;
}