.portfolio-container {
  color: white;
  text-shadow: 3px 7px 3px black;
  font-size: 70px;
  padding-top: 100px;
  width: 100vw;
}

.projects-container {
  text-align: center;
  margin: 0 auto;
  justify-content: center;
  flex-direction: row;
}

.projects {
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  width: 100%;
  margin: 0 auto;
}

.project-link {
  width: 360px;
  height: 450px;
  background-size: contain;
  margin: -50px 20px 0px 20px ;
}

.project-img {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.project-info-container {
  font-size: 40px;
  width: 80%;
  margin: 0 auto;
}

@media only screen and (max-width: 1024px) {
  .portfolio-container {
    font-size: 55px;
    padding-top: 140px;
  }
  
  .projects-container {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    height: 90vh;
  }
}

@media only screen and (max-width: 600px) {
  .portfolio-container {
    font-size: 52px;
    padding-top: 25px;
  }
  
  .projects-container {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    height: 205vh;
  }
  
  .project-link {
    width: 360px;
    height: 300px;
    margin: -20px 20px 50px 20px ;
  }
  
  .project-img {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
  
  .project-info-container {
    font-size: 40px;
    width: 80%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 280px){
  .portfolio-container {
    font-size: 34px;
    padding-top: 25px;
  }
  
  .projects-container {
    height: 220vh;
  }
  
  .project-link {
    width: 360px;
    height: 300px;
    margin: -20px 20px 50px 20px ;
  }
  
  .project-img {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
  
  .project-info-container {
    font-size: 30px;
    width: 80%;
    margin: 0 auto;
  }
}