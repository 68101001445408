.home-container {
  font-family: 'Cinzel', serif;
  text-align: center;
  margin: 0 auto;
  font-size: 120px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  color: rgb(250, 250, 250);
  text-shadow: 5px 5px rgba(0, 0, 0, 0.5);
}

.home-info-container {
  margin-top: 35px;
  padding: 0;
}

.big-button-container {
  width: 550px;
  height: 80px;
  margin: 0 auto;
  margin-top: -95px;
}

.big-button {
  background-image: url("../../assets/exlargebuttonlight.png");
  background-color: transparent;
  font-family: 'Cinzel', serif;
  border: none;
  width: 550px;
  height: 80px;
  font-size: 60px;
  color: rgb(236, 224, 224);
  margin: 0 auto;
  padding-top: 6px;
}

.big-button:hover {
  background-image: url("../../assets/exlargebuttondark.png");
  color: rgb(105, 105, 105);
  font-size: 57px;
  padding-top: 9px;
}

@media only screen and (max-width: 600px) {
  .home-container {
    font-size: 65px;
  }
  
  .home-info-container {
    margin-top: 30px;
    padding: 0;
  }
  
  .big-button-container {
    width: 350px;
    height: 70px;
    margin: 0 auto;
  }
  
  .big-button {
    background-image: url("../../assets/largebuttonlight.png");
    background-color: transparent;
    width: 350px;
    height: 50px;
    font-size: 35px;
    padding-top: 6px;
  }
  
  .big-button:hover {
    background-image: url("../../assets/largebuttondark.png");
    color: rgb(105, 105, 105);
    font-size: 32px;
    padding-top: 9px;
  }
}

@media only screen and (max-width: 280px) {
  .home-container {
    font-size: 45px;
  }
  
  .home-info-container {
    margin-top: 30px;
    padding: 0;
  }
  
  .big-button-container {
    width: 160px;
    height: 40px;
    margin: 0 auto;
  }
  
  .big-button {
    background-image: url("../../assets/buttonlight.png");
    background-color: transparent;
    width: 160px;
    height: 40px;
    font-size: 30px;
    padding-top: 0px;
  }
  
  .big-button:hover {
    background-image: url("../../assets/buttondark.png");
    color: rgb(105, 105, 105);
    font-size: 28px;
    padding-top: 0px;
  }
}