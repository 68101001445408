.footer {
  display: flex;
  margin-top: 55px;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  /* bottom: 0; */
}

.footer-icon-container {
  display: flex;
}

.footer-icon {
  width: 75px;
  margin: 0px auto;
  padding: 15px;
}

@media only screen and (max-width: 320px){
  .footer {
    margin-top: 40px;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    bottom: 0;
  }
  
  .footer-icon-container {
    display: flex;
  }
  
  .footer-icon {
    width: 75px;
    margin: 0px auto;
    padding: 15px;
  }

}