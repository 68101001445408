.project-details {
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  padding-top: 80px;
}

.project-name {
  color: white;
  font-size: 100px;
  text-shadow: 2px 2px #000000;
  text-transform: uppercase;
  text-align: center;
}

.project-details-container {
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-top: -50px;
  width: 65%;
}

.column {
  width: 100%;
  padding: 0px 25px 0px 25px;
}

#project-img {
  width: 100%;
  box-shadow: 3px 3px 5px black;
}

.subtext {
  color: white;
  font-size: 36px;
}

.text {
  margin-top: 0px;
  color: white;
  font-size: 26px;
}

.details-button-container {
  display: flex;
  width: 317px;
  margin: 0px auto;
  height: 42px;
}

.details-button {
  font-size: 28px;
  width: 317px;
  background-image: url("../../assets/buttonlight.png");
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.details-button:hover {
  background-image: url("../../assets/buttondark.png");
  color: rgb(105, 105, 105);
}

.code {
  padding-top: 3px;
}

.web {
  font-size: 24px;
  padding-top: 6px;
}

@media only screen and (max-width: 600px) {
  .project-details {
    width: 100%;
    height: 100%;
    min-height: 10vh;
    justify-content: center;
    flex-direction: column;
    padding-top: 30px;
  }
  
  .project-name {
    color: white;
    font-size: 45px;
    text-shadow: 2px 2px #000000;
    text-transform: uppercase;
    text-align: center;
  }
  
  .project-details-container {
    height: 100%;
    display: block;
    align-items: center;
    text-align: justify;
    margin: 0 auto;
    width: 90%;
  }
  
  .column {
    width: 100%;
    padding: 0px;
  }
  
  #project-img {
    width: 100%;
    box-shadow: 3px 3px 5px black;
  }
  
  .subtext {
    text-align: center;
    color: white;
    font-size: 36px;
  }
  
  .text {
    margin-top: 0px;
    color: white;
    font-size: 26px;
  }
  
  .details-button-container {
    display: flex;
    width: 317px;
    margin: 0px auto;
    height: 42px;
  }

  .language {
    text-align: center;
  }
  
  .details-button {
    font-size: 28px;
    width: 317px;
    background-image: url("../../assets/buttonlight.png");
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
  
  .details-button:hover {
    background-image: url("../../assets/buttondark.png");
    color: rgb(105, 105, 105);
  }
  
  .code {
    padding-top: 3px;
  }
  
  .web {
    font-size: 24px;
    padding-top: 6px;
  }
}

@media only screen and (max-width: 280px){
  .project-details {
    width: 100%;
    height: 100%;
    min-height: 10vh;
    justify-content: center;
    flex-direction: column;
    padding-top: 30px;
  }
  
  .project-name {
    color: white;
    font-size: 30px;
    text-shadow: 2px 2px #000000;
    text-transform: uppercase;
    text-align: center;
  }

  .subtext {
    text-align: center;
    color: white;
    font-size: 25px;
  }
  
  .text {
    margin-top: 0px;
    color: white;
    font-size: 20px;
  }

  .details-button-container {
    display: block;
    width: 157px;
    margin: 0px auto;
    height: 42px;
  }
  
  .details-button {
    font-size: 28px;
    width: 157px;
  }
}